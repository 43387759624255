import * as React from 'react'

import { Box } from '@thg-commerce/gravity-system'
import { spacing, styled } from '@thg-commerce/gravity-theme'

import ErrorMessage from '../ErrorMessage'
import { Label, LabelText } from '../FormItem/Label'

interface RadioI18nText {
  optionalLabel?: string
  requiredError?: string
}

export interface RadioGroupProps {
  label: string
  selectedValue?: string
  labelHidden: boolean
  columns: number
  getState?: Function
  children?: any
  disabled?: boolean
  required?: boolean
  i18nText?: RadioI18nText
  error?: string | boolean
  inView?: boolean
  bindref?: any
  className?: string
  offset?: number
  horizontal?: boolean
  defaultValue?: string
  noWrap?: boolean
  removeGap?: boolean
  labelCustomComponent?: React.ReactNode
  textStyle?: string
  setFormValues?: (formValues: string[]) => void
}

const RadioGroupLabelWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  border: none;
  box-sizing: border-box;
  padding: ${spacing(0)};
  margin-right: ${spacing(1)};
  width: 100%;
  pointer-events: ${(props) => (props.disabled ? 'none' : '')};
`

const LabelWrapper = styled(Label)<{
  selectedValue: boolean
}>`
  display: flex;
  flex-direction: ${(props) => (!!props.selectedValue ? 'row' : 'column')};
`

export const StyledBox = styled(Box)<{
  horizontal?: boolean
  noWrap?: boolean
  removeGap?: boolean
}>`
  display: ${(props) => (props.horizontal ? 'flex' : 'block')};
  flex-direction: ${(props) => (props.horizontal ? 'row' : 'initial')};
  gap: ${(props) =>
    props.removeGap
      ? '0'
      : props.horizontal
      ? `0 ${spacing(2.25)}`
      : 'initial'};
  width: ${(props) => (props.horizontal ? '100%' : 'auto')};

  ${(props) => (props.noWrap ? 'flex-wrap: nowrap;' : 'flex-wrap: wrap;')}
`

const StyledLabelText = styled(LabelText)<{
  required?: boolean
}>`
  ${(props) => !props.required && `padding-left: ${spacing(1)};`}
`

/** @component */
const RadioGroupWrapperComponent = (props) => {
  const {
    error,
    radioGroupState,
    focusRef,
    setError,
    setRadioGroupState,
  } = props

  const errorMessageId = `${props.label}-error`
  const childCount = React.Children.count(props.children)

  return (
    <RadioGroupLabelWrapper
      data-testid="radio-wrapper"
      aria-label={props.label}
      disabled={props.disabled}
    >
      <StyledBox
        horizontal={props.horizontal}
        noWrap={props.noWrap}
        removeGap={props.removeGap}
      >
        {props.children &&
          React.Children.map(props.children, (RadioGroupElement, index) => {
            return React.cloneElement(
              RadioGroupElement,
              {
                error,
                radioGroupState,
                isSingleOption: childCount === 1,
                focusref: index === 0 ? focusRef : null,
                disabled: props.disabled,
                setGroupState: (state: string) => {
                  setRadioGroupState(state)
                  setError(false)
                },
                ariaDescribedBy: errorMessageId
                  ?.toLowerCase()
                  .replace(/\:|\?|\.|\!|\"|\'/g, '')
                  .split(' ')
                  .join('-'),
                ...RadioGroupElement.props,
              },
              RadioGroupElement.props.children,
            )
          })}
      </StyledBox>
    </RadioGroupLabelWrapper>
  )
}

const LabelWrapperComponent = (props) => {
  const {
    inputLabelEl,
    radioGroupState,
    inputEl,
    requiredProps,
    setRadioGroupState,
  } = props

  return (
    <LabelWrapper
      selectedValue={!!props.selectedValue}
      className={props.disabled ? 'disabled' : ''}
      ref={inputLabelEl}
      labelCustomComponent={Boolean(props.labelCustomComponent)}
    >
      <div style={{ display: 'flex', width: '100%' }}>
        <LabelText
          className={props.labelHidden ? 'hidden' : ''}
          required={props.required}
          disabled={props.disabled}
          optionalText={
            props.i18nText && props.i18nText.optionalLabel
              ? props.i18nText.optionalLabel
              : !props.selectedValue
              ? 'Optional'
              : ''
          }
          textStyle={props.textStyle}
        >
          {props.selectedValue ? `${props.label}:` : props.label}
        </LabelText>
        {props.selectedValue && (
          <StyledLabelText
            className={props.labelHidden ? 'hidden' : ''}
            required={props?.required}
            disabled={props.disabled}
            optionalText={''}
            labelIsSelectedValue={true}
          >
            {props.selectedValue || ''}
          </StyledLabelText>
        )}
      </div>
      <input
        type={`hidden`}
        value={radioGroupState}
        ref={inputEl}
        {...requiredProps}
        onChange={(_: React.ChangeEvent<HTMLInputElement>) => {
          if (inputEl !== null && inputEl.current !== null) {
            setRadioGroupState(inputEl.current.value)
          }
        }}
      />
      {props.labelCustomComponent ? props.labelCustomComponent : ''}
    </LabelWrapper>
  )
}

export const RadioGroup = (props: RadioGroupProps) => {
  const inputEl: React.RefObject<HTMLInputElement> = React.useRef<
    HTMLInputElement
  >(null)

  const [radioGroupState, setRadioGroupState] = React.useState(
    () =>
      props.bindref?.current?.value ||
      inputEl.current?.value ||
      props.defaultValue ||
      '',
  )

  React.useEffect(() => {
    props.setFormValues && props.setFormValues([radioGroupState])
  }, [radioGroupState])

  const [error, setError] = React.useState<string | boolean>(false)

  const inputLabelEl: React.RefObject<HTMLLabelElement> = React.useRef<
    HTMLLabelElement
  >(null)

  const focusRef: React.RefObject<HTMLInputElement> = React.useRef<
    HTMLInputElement
  >(null)

  React.useEffect(() => {
    if (props.error) {
      setError(props.error)
    }
  }, [props.error])

  React.useEffect(() => {
    props.getState && props.getState(radioGroupState)
  }, [radioGroupState])

  const offsetTop =
    inputLabelEl != null && inputLabelEl.current != null
      ? inputLabelEl.current.offsetTop - Number(props.offset)
      : 0

  React.useImperativeHandle(props.bindref, () => ({
    ...(props as RadioGroupProps & HTMLInputElement),
    offsetTop,
    focus: () => {
      if (focusRef !== null && focusRef.current !== null) {
        focusRef.current.focus()
      }
    },
    validate: (): boolean | undefined => {
      const valid = props.required && radioGroupState !== ''
      if (!valid && inputEl !== null && inputEl.current !== null) {
        setError(
          props?.i18nText?.requiredError?.replace('${path}', props.label) ||
            `${props.label} is a required field`,
        )
      }
      return valid
    },
    value: radioGroupState,
    inView: props.inView,
  }))

  const requiredProps = {
    required: props.required,
    'aria-required': props.required,
  }

  const errorMessageId = `${props.label}-error`

  return (
    <div
      role="radiogroup"
      className={props.className}
      data-testid={`${props.label}-radio-wrapper`}
    >
      <LabelWrapperComponent
        {...props}
        requiredProps={requiredProps}
        inputEl={inputEl}
        inputLabelEl={inputLabelEl}
        radioGroupState={radioGroupState}
        setRadioGroupState={setRadioGroupState}
      />
      <RadioGroupWrapperComponent
        {...props}
        error={error}
        radioGroupState={radioGroupState}
        focusRef={focusRef}
        setError={setError}
        setRadioGroupState={setRadioGroupState}
      />
      {error && <ErrorMessage id={errorMessageId} error={error} />}
    </div>
  )
}

export default RadioGroup
