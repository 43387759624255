import * as React from 'react'
import loadable from '@loadable/component'
import FocusTrap from 'focus-trap-react'
import { useReducedMotion } from 'framer-motion'
import { vsprintf } from 'sprintf-js'

const Close = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Close'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const Home = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/Home'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)
const ChevronLeft = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/ChevronLeft'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

const SvgIcon = loadable(
  () => import('@thg-commerce/gravity-icons/src/components/SvgIcon'),
  { ssr: true, fallback: <div style={{ width: 24, height: 24 }} /> },
)

import { HeaderSlot } from '@thg-commerce/enterprise-config'
import { CustomAnimatePresence } from '@thg-commerce/gravity-animations'
import { CombinedThemeInterface } from '@thg-commerce/gravity-patterns'
import { FlyoutDirection } from '@thg-commerce/gravity-patterns/Header/theme'
import { Tab, TabList } from '@thg-commerce/gravity-patterns/Tabs/styles'
import { useTheme } from '@thg-commerce/gravity-patterns/theme'
import { useScrollLock } from '@thg-commerce/gravity-system'
import { IconStyling } from '@thg-commerce/gravity-theme/icons'

import { FlyoutIconTypes } from '../Header'
import { HeaderSlots } from '../HeaderSlots'
import {
  NavigationEventData,
  PromotionsTextColor,
} from '../Navigation/Navigation'
import { CardNavigationContent } from '../Navigation/NavigationContent/CardNavigationContent'
import {
  ImageCardNavigationContent,
  StyledCard as StyledImageCard,
  StyledImg as StyledNavImg,
  StyledLinkText as StyledImageText,
} from '../Navigation/NavigationContent/ImageCardNavigationContent'
import {
  ImageThumbnailContent,
  StyledCard,
  StyledImg,
  StyledLinkText,
  SubNavigationLinkWrapper,
} from '../Navigation/NavigationContent/ImageThumbnailContent'
import { NavigationTextItem } from '../NavigationItems'
import {
  NavigationImage,
  NavigationItem,
  NavigationItemType,
  NavigationLink,
  NavigationTree,
} from '../NavigationTree'
import { Submenu } from '../Submenu'
import { SubmenuProps } from '../Submenu/Submenu'
import { removeSpacesAndCharacters } from '../Utils'

import {
  FlyoutFooter,
  FlyoutHeader,
  FlyoutHeaderContent,
  FlyoutHeaderWrapper,
  FlyoutMenuContent,
  FlyoutMenuLink,
  FlyoutMenuLinkContainer,
  FlyoutMenuLinkIconContainer,
  FlyoutNav,
  FlyoutOverlay,
  HeaderButton,
  HeaderButtonText,
  HeaderLink,
  NavigationLabel,
  NavList,
  ResponsiveFlyoutMenuIcon,
  Space,
  StyledChevronRight,
  StyledFlyoutMenu,
  StyledLi,
  StyledMotionDiv,
} from './styles'

// TODO: CSBOM-397 | to make headerPromotionUrl into an array of values
export interface FlyoutMenuProps {
  isShowing: boolean
  navigationTree: NavigationTree
  onClose: () => void
  i18nText: FlyoutMenuI18nText
  submenuProps?: SubmenuProps
  clickOutsideDeactivates: boolean
  hideHome?: boolean
  sloti18nText?: { text: string; url: string }[]
  slotConfig: HeaderSlot[]
  responsiveFlyoutIcons?: { type: string; value?: boolean }[]
  flyoutPosition: FlyoutDirection
  enableThreeTierNav?: boolean
  headerPromotionUrl?: string
  headerPromotionUrlTwo?: string
  headerPromotionUrls?: { url: string; color: string }[]
  selectTopLevelNavigationItem?: (path: string) => void
  homeButtonOnClick?: () => void
  storeLocatorConfig?: boolean
  primaryNavImages?: string[]
  selectedTopLevelNavigationItemIndex?: number
  navigationOnClickCallback?: (data: NavigationEventData) => void
  hasSelectedTopLevelItem: boolean
  navSubLevelPromotionList?: string[]
  navLevelThreePromotionList?: string[]
  hideThreeTierTab?: boolean
  showNavigationLabel?: boolean
  hideFocusOnLevelOneNav?: boolean
}

export interface FlyoutMenuI18nText {
  linkWithChildrenLabel: string
  homeLabel: string
  close: string
  back: string
  sectionHome?: string
  wishlist?: string
  headerText?: string
}

interface FlyoutEnhanced {
  selectedTopLevelNavigation?: string
  selectedSecondLevelNavigation?: string
  onClickCallback?: (data: NavigationEventData) => void
}

export const FlyoutHeaderLink = ({
  text,
  icon,
  url,
  storeLocatorConfig,
  headerText,
}: {
  text?: string
  icon?: React.ReactElement
  url?: string
  storeLocatorConfig?: boolean
  headerText?: string
}) => {
  if (!storeLocatorConfig && text === headerText) {
    return null
  }

  return (
    <FlyoutMenuLinkContainer href={url}>
      <FlyoutMenuContent>
        <FlyoutMenuLinkIconContainer>{icon}</FlyoutMenuLinkIconContainer>
        <FlyoutMenuLink>{text}</FlyoutMenuLink>
      </FlyoutMenuContent>
      <StyledChevronRight />
    </FlyoutMenuLinkContainer>
  )
}

const flyoutCallback = (
  data: string,
  flyoutNavigation?: FlyoutEnhanced,
  currentLevel?: number,
) => {
  if (flyoutNavigation?.onClickCallback) {
    const selectedTopLevelNavigation =
      currentLevel === 0
        ? data
        : flyoutNavigation.selectedTopLevelNavigation || ''
    const selectedSecondLevelNavigation = currentLevel === 1 ? data : ''

    flyoutNavigation.onClickCallback({
      selectedTopLevelNavigation,
      selectedSecondLevelNavigation,
    })
  }
}

const displayCardBackground = (currentLevelData) => {
  return currentLevelData
    ? currentLevelData?.type === NavigationItemType.TEXT_CARD ||
        currentLevelData?.type === NavigationItemType.IMAGE_CARD ||
        currentLevelData?.type === NavigationItemType.THUMBNAIL_CARD
    : false
}

export const flyoutIcons = (
  storeIconTheme: IconStyling,
  storeAriaLabel?: string,
  storeUrl?: string,
  wishlistAriaLabel?: string,
  wishlistUrl?: string,
) => {
  return {
    [FlyoutIconTypes.STORE]: {
      svgPathElement: storeIconTheme.svgPath ? (
        <SvgIcon
          xmlns="http://www.w3.org/2000/svg"
          viewBox={storeIconTheme.viewBox}
          width={storeIconTheme.width}
          height={storeIconTheme.height}
        >
          <path d={storeIconTheme.svgPath} fillRule="evenodd"></path>
        </SvgIcon>
      ) : (
        <ResponsiveFlyoutMenuIcon height={24} width={24} viewBox="0 0 46.99 48">
          <path
            d="M36.52 15.68c-1.29-7.68-6.25-9.92-10-10.5a15 15 0 00-4.42 0c-3.63.6-8.36 2.88-9.63 10.48C10.35 28.26 24.07 42.54 24.5 43c.43-.44 14.14-14.71 12-27.29m-12 8.25a5.46 5.46 0 01-5.24-5.65 5.46 5.46 0 015.24-5.66 5.45 5.45 0 015.23 5.66 5.45 5.45 0 01-5.23 5.65"
            fillRule="evenodd"
          ></path>
        </ResponsiveFlyoutMenuIcon>
      ),
      i18nText: {
        ariaLabel: storeAriaLabel,
        url: storeUrl,
      },
    },
    [FlyoutIconTypes.WISHLIST]: {
      svgPathElement: (
        <ResponsiveFlyoutMenuIcon height={24} width={24} viewBox="0 0 24 24">
          <path
            d="M11.91 6.73c-1.55-1.68-4-3.45-5.71-3.21C2 4.1 1.11 8.1 3.22 12.68c1.56 3.38 7 7.82 8.69 7.82s6.8-4.16 8.47-7.83c2-4.43.93-8.45-2.91-9.07-1.75-.28-4 1.4-5.56 3.13z"
            fillRule="evenodd"
          ></path>
        </ResponsiveFlyoutMenuIcon>
      ),
      i18nText: {
        ariaLabel: wishlistAriaLabel,
        url: wishlistUrl,
      },
    },
  }
}

// TODO: REBUILD-9146 Add types for all the parameters
const renderLevelOne = (
  data: NavigationItem,
  currentLevel: number,
  levelHome: React.ReactNode,
  currentLevelData: NavigationItem | null,
  setCurrentLevelData: React.Dispatch<
    React.SetStateAction<NavigationItem | null>
  >,
  setCurrentLevel,
  setPreviousLevelData,
  setPreviousLevelHome,
  setFirstLevelData,
  setLevelHome,
  setTransitionDirection,
  setSelectedTabIndex,
  i18nText: {
    linkWithChildrenLabel: string
    sectionHome?: string
  },
  setLabel: React.Dispatch<React.SetStateAction<string | undefined>>,
  index?: number,
  enableThreeTierNav?: boolean,
  selectTopLevelNavigationItem?: (path: string) => void,
  flyoutNavigation?: FlyoutEnhanced,
  setTabNumber?: (value) => void,
) => {
  setLabel(data?.displayName)
  setPreviousLevelHome(levelHome)
  setLevelHome(
    <NavigationTextItem
      data-testid="flyout-menu-level-one"
      text={vsprintf(i18nText.sectionHome || '', [data.displayName])}
      hasChildren={false}
      link={data.link}
      enableThreeTierNav={enableThreeTierNav}
      showBottomBorder={enableThreeTierNav}
      shouldFocus
      isLevelHome={true}
      eventHandler={getEventHandler(
        { flyoutNavigation },
        currentLevel,
      )}
    />,
  )
  setTransitionDirection(1)
  setPreviousLevelData(currentLevelData)
  setCurrentLevel(currentLevel + 1)
  setCurrentLevelData(data)
  setSelectedTabIndex(index)
  if (currentLevel === 0) {
    setFirstLevelData(data)
    selectTopLevelNavigationItem &&
      data?.link?.url &&
      selectTopLevelNavigationItem(data?.link.url)
    !enableThreeTierNav && setTabNumber && setTabNumber(index)
  }
  if (currentLevel === 0 || 1) {
    flyoutCallback(data?.displayName ?? '', flyoutNavigation, currentLevel)
  }
}
const SubNavLinkWithImage = (props: {
  image: NavigationImage
  displayName: string
  link?: NavigationLink
  enableThreeTierNav?: boolean
}) => (
  <SubNavigationLinkWrapper
    dynamicWidths={false}
    mobile={true}
    totalCards={1}
    threeTierNav={props.enableThreeTierNav}
  >
    <StyledCard href={props.link?.url}>
      <StyledImg
        src={props.image.url}
        mobileImageWidth="44px"
        mobileImageHeight="44px"
        alt={props.image.alt || ''}
      />

      <StyledLinkText>{props.displayName}</StyledLinkText>
    </StyledCard>
  </SubNavigationLinkWrapper>
)

const renderNavigationItem = (
  data: NavigationItem,
  currentLevel: number,
  shouldFocusAtLevelZero: boolean,
  levelHome: React.ReactNode,
  currentLevelData: NavigationItem | null,
  firstLevelData: NavigationItem | undefined,
  setCurrentLevelData: React.Dispatch<
    React.SetStateAction<NavigationItem | null>
  >,
  setCurrentLevel,
  setPreviousLevelData,
  setPreviousLevelHome,
  setFirstLevelData,
  setLevelHome,
  setTransitionDirection,
  setSelectedTabIndex,
  i18nText: {
    linkWithChildrenLabel: string
    sectionHome?: string
  },
  setLabel: React.Dispatch<React.SetStateAction<string | undefined>>,
  theme: CombinedThemeInterface,
  clickEventHandler: () => void,
  index?: number,
  enableThreeTierNav?: boolean,
  selectTopLevelNavigationItem?: (path: string) => void,
  thirdLevelLink?: boolean,
  headerPromotionUrl?: string,
  headerPromotionUrlTwo?: string,
  headerPromotionUrls?: { url: string; color: string }[],
  navSubLevelPromotionList?: string[],
  navLevelThreePromotionList?: string[],
  hideFocusOnLevelOneNav?: boolean,
  flyoutNavigation?: FlyoutEnhanced,
  setTabNumber?: (value) => void,
) => {
  const navItemOnClick = () => {
    if (data?.subNavigation) {
      if (currentLevel === 0 || currentLevel === 1) {
        renderLevelOne(
          data,
          currentLevel,
          levelHome,
          currentLevelData,
          setCurrentLevelData,
          setCurrentLevel,
          setPreviousLevelData,
          setPreviousLevelHome,
          setFirstLevelData,
          setLevelHome,
          setTransitionDirection,
          setSelectedTabIndex,
          i18nText,
          setLabel,
          index,
          enableThreeTierNav,
          selectTopLevelNavigationItem,
          flyoutNavigation,
          setTabNumber,
        )
      }
    }
  }

  switch (data.type) {
    case NavigationItemType.IMAGE_CARD:
    case NavigationItemType.TEXT:
    case NavigationItemType.BRANDS:
    default:
      if (data.image) {
        return (
          <StyledLi
            currentLevel={currentLevel}
            key={index}
            enableThreeTierNav={enableThreeTierNav}
            hideBottomBorder
          >
            <StyledImageCard
              tabIndex={0}
              onClick={() => {
                if (data.subNavigation) {
                  setLabel(data?.displayName)
                  setPreviousLevelHome(levelHome)
                  setLevelHome(
                    <NavigationTextItem
                      data-testid="flyout-navigation-text-item"
                      text={vsprintf(i18nText.sectionHome || '', [
                        data.displayName,
                      ])}
                      hasChildren={false}
                      link={data.link}
                      enableThreeTierNav={enableThreeTierNav}
                      showBottomBorder={enableThreeTierNav}
                      shouldFocus
                      isLevelHome={true}
                      navLevelThreePromotionList={navLevelThreePromotionList}
                      topLevelName={firstLevelData?.displayName}
                    />,
                  )
                  setTransitionDirection(1)
                  setPreviousLevelData(currentLevelData)
                  setCurrentLevel(currentLevel + 1)
                  setCurrentLevelData(data)
                  setSelectedTabIndex(index)
                  setTabNumber && setTabNumber(index)
                  selectTopLevelNavigationItem &&
                    data?.link?.url &&
                    selectTopLevelNavigationItem(data.link.url)
                }
              }}
              currentLevel={currentLevel}
              href={currentLevel === 1 && data.link?.url}
            >
              <StyledNavImg
                src={data.image.url}
                width="100%"
                height={
                  theme.patterns.header.navigation.imageCard.image.height
                    ? theme.patterns.header.navigation.imageCard.image.height
                    : 'auto'
                }
                alt={data.image.alt || ''}
                onClick={navItemOnClick}
              />
              <StyledImageText>{data.displayName}</StyledImageText>
            </StyledImageCard>
          </StyledLi>
        )
      }

      return (
        <StyledLi
          key={index}
          enableThreeTierNav={enableThreeTierNav}
          currentLevel={currentLevel}
          thirdLevelLink={thirdLevelLink}
        >
          <TextNavItemMainContent
            navItemOnClick={navItemOnClick}
            data={data}
            currentLevel={currentLevel}
            shouldFocusAtLevelZero={shouldFocusAtLevelZero}
            i18nText={i18nText}
            theme={theme}
            index={index}
            enableThreeTierNav={enableThreeTierNav}
            thirdLevelLink={thirdLevelLink}
            headerPromotionUrl={headerPromotionUrl}
            headerPromotionUrlTwo={headerPromotionUrlTwo}
            headerPromotionUrls={headerPromotionUrls}
            navSubLevelPromotionList={navSubLevelPromotionList}
            navLevelThreePromotionList={navLevelThreePromotionList}
            firstLevelData={firstLevelData}
            hideFocusOnLevelOneNav={hideFocusOnLevelOneNav}
            flyoutNavigation={flyoutNavigation}
            clickEventHandler={clickEventHandler}
          />
          {currentLevel === 2 &&
            data.subNavigation &&
            data.subNavigation.map(
              (subNavigationItem, subNavigationItemIndex) => {
                return renderNavigationItem(
                  subNavigationItem,
                  currentLevel,
                  shouldFocusAtLevelZero,
                  levelHome,
                  currentLevelData,
                  firstLevelData,
                  setCurrentLevelData,
                  setCurrentLevel,
                  setPreviousLevelData,
                  setPreviousLevelHome,
                  setFirstLevelData,
                  setLevelHome,
                  setTransitionDirection,
                  setSelectedTabIndex,
                  i18nText,
                  setLabel,
                  theme,
                  clickEventHandler,
                  subNavigationItemIndex,
                  enableThreeTierNav,
                  selectTopLevelNavigationItem,
                  true,
                  headerPromotionUrl,
                  headerPromotionUrlTwo,
                  headerPromotionUrls,
                  navSubLevelPromotionList,
                  navLevelThreePromotionList,
                  flyoutNavigation,
                  setTabNumber,
                )
              },
            )}
        </StyledLi>
      )
  }
}

const getHasChildren = (data: NavigationItem, currentLevel: number) =>
  ((currentLevel === 0 || currentLevel === 1) &&
    data.subNavigation &&
    data?.subNavigation?.length > 0) ||
  false

const getAriaLabel = (
  data: NavigationItem,
  i18nText: {
    linkWithChildrenLabel: string
    sectionHome?: string
  },
) =>
  (data &&
    data?.subNavigation &&
    vsprintf(i18nText.linkWithChildrenLabel, [data.displayName])) ||
  undefined

const getShouldFocus = (
  shouldFocusAtLevelZero: boolean,
  currentLevel: number,
  index?: number,
) => shouldFocusAtLevelZero && index === 0 && currentLevel === 0

const checkPromotionUrl = (url, dataLinkUrl) => url && url === dataLinkUrl

const getEventHandler = (props, currentLevel, clickEventHandler?) => {
  if (props.flyoutNavigation?.onClickCallback) {
    return (e) => {
      if (currentLevel === 2) {
        props.flyoutNavigation?.onClickCallback?.({
          selectedTopLevelNavigation:
            props.flyoutNavigation?.selectedTopLevelNavigation ?? '',
          selectedSecondLevelNavigation:
            props.flyoutNavigation?.selectedSecondLevelNavigation,
          selectedThirdLevelNavigation: e,
        })
      } else if (currentLevel === 1) {
        props.flyoutNavigation?.onClickCallback?.({
          selectedTopLevelNavigation:
            props.flyoutNavigation?.selectedTopLevelNavigation ?? '',
          selectedSecondLevelNavigation: e,
        })
      } else if (currentLevel === 0) {
        props.flyoutNavigation?.onClickCallback?.({
          selectedTopLevelNavigation: e,
        })
      }
      clickEventHandler()
    }
  }
  return clickEventHandler
}

const SubNavLinkWithoutImage = (props: {
  navItemOnClick
  data: NavigationItem
  currentLevel: number
  shouldFocusAtLevelZero: boolean
  i18nText: {
    linkWithChildrenLabel: string
    sectionHome?: string
  }
  theme: CombinedThemeInterface
  index?: number
  enableThreeTierNav?: boolean
  thirdLevelLink?: boolean
  headerPromotionUrl?: string
  headerPromotionUrlTwo?: string
  headerPromotionUrls?: { url: string; color: string }[]
  navSubLevelPromotionList?: string[]
  navLevelThreePromotionList?: string[]
  firstLevelData?: NavigationItem
  hideFocusOnLevelOneNav?: boolean
  flyoutNavigation?: FlyoutEnhanced
  clickEventHandler: () => void
}) => {
  const theme = useTheme()
  const promotionColor = PromotionsTextColor(
    props.data,
    props.headerPromotionUrl,
    props.headerPromotionUrlTwo,
    props.headerPromotionUrls,
    theme,
  )

  const hasHeaderPromotionUrl = checkPromotionUrl(
    props.headerPromotionUrl,
    props.data.link?.url,
  )
  const hasHeaderPromotionUrlTwo = checkPromotionUrl(
    props.headerPromotionUrlTwo,
    props.data.link?.url,
  )

  const secondLevelMenuName =
    props.currentLevel === 2
      ? props.flyoutNavigation?.selectedSecondLevelNavigation
      : ''

  const sanitisedMenuText = removeSpacesAndCharacters(secondLevelMenuName)

  return (
    <NavigationTextItem
      data-cs-override-id={`${props.flyoutNavigation?.selectedTopLevelNavigation}${sanitisedMenuText}`}
      data-testid="flyout-menu-without-image"
      text={props.data.displayName}
      promotionTextColour={promotionColor}
      promotionBackgroundColour={
        hasHeaderPromotionUrl
          ? props.theme?.patterns.header.navigation.promotionItem
              .backgroundColour
          : ''
      }
      promotionBackgroundColourTwo={
        hasHeaderPromotionUrlTwo
          ? props.theme?.patterns.header.navigation.promotionItem
              .backgroundColourTwo
          : ''
      }
      link={props.data.link}
      currentLevel={props.currentLevel}
      hasChildren={getHasChildren(props.data, props.currentLevel)}
      aria-label={getAriaLabel(props.data, props.i18nText)}
      shouldFocus={getShouldFocus(
        !props.hideFocusOnLevelOneNav ?? props.shouldFocusAtLevelZero,
        props.currentLevel,
        props.index,
      )}
      onClick={props.navItemOnClick}
      enableThreeTierNav={props.enableThreeTierNav}
      navSubLevelPromotionList={props.navSubLevelPromotionList}
      thirdLevelLink={props.thirdLevelLink}
      navLevelThreePromotionList={props.navLevelThreePromotionList}
      topLevelName={props.firstLevelData?.displayName}
      eventHandler={getEventHandler(
        props,
        props.currentLevel,
        props.clickEventHandler,
      )}
    />
  )
}

const TextNavItemMainContent = (props: {
  navItemOnClick
  data: NavigationItem
  currentLevel: number
  shouldFocusAtLevelZero: boolean
  i18nText: {
    linkWithChildrenLabel: string
    sectionHome?: string
  }
  theme: CombinedThemeInterface
  index?: number
  enableThreeTierNav?: boolean
  thirdLevelLink?: boolean
  headerPromotionUrl?: string
  headerPromotionUrlTwo?: string
  headerPromotionUrls?: { url: string; color: string }[]
  flyoutNavigation?: FlyoutEnhanced
  navSubLevelPromotionList?: string[]
  navLevelThreePromotionList?: string[]
  firstLevelData?: NavigationItem
  hideFocusOnLevelOneNav: boolean
  clickEventHandler: () => void
}) =>
  props.data.image ? (
    <SubNavLinkWithImage
      image={props.data.image}
      displayName={props.data.displayName}
      link={props.data.link}
      enableThreeTierNav={props.enableThreeTierNav}
    />
  ) : (
    <SubNavLinkWithoutImage
      navItemOnClick={props.navItemOnClick}
      data={props.data}
      currentLevel={props.currentLevel}
      shouldFocusAtLevelZero={props.shouldFocusAtLevelZero}
      i18nText={props.i18nText}
      theme={props.theme}
      index={props.index}
      enableThreeTierNav={props.enableThreeTierNav}
      thirdLevelLink={props.thirdLevelLink}
      headerPromotionUrl={props.headerPromotionUrl}
      headerPromotionUrlTwo={props.headerPromotionUrlTwo}
      headerPromotionUrls={props.headerPromotionUrls}
      navSubLevelPromotionList={props.navSubLevelPromotionList}
      navLevelThreePromotionList={props.navLevelThreePromotionList}
      firstLevelData={props.firstLevelData}
      hideFocusOnLevelOneNav={props.hideFocusOnLevelOneNav}
      flyoutNavigation={props.flyoutNavigation}
      clickEventHandler={props.clickEventHandler}
    />
  )

const HeaderIcons = (props: {
  theme: CombinedThemeInterface
  i18nText: FlyoutMenuI18nText
  homeButtonOnClick?: () => void
  hideHome?: boolean
  responsiveFlyoutIcons?: { type: string; value?: boolean }[]
  sloti18nText?: { text: string; url: string }[]
}) => {
  const {
    theme,
    i18nText,
    homeButtonOnClick,
    hideHome,
    responsiveFlyoutIcons,
    sloti18nText,
  } = props

  const flyoutMenuIcons = flyoutIcons(
    theme.patterns.header.flyout.storeIcon,
    sloti18nText?.[1]?.text,
    sloti18nText?.[1]?.url,
    sloti18nText?.[2]?.text,
    sloti18nText?.[2]?.url,
  )
  return !hideHome ? (
    <React.Fragment>
      <HeaderLink
        aria-label={i18nText.homeLabel}
        href="/"
        onClick={homeButtonOnClick && homeButtonOnClick}
      >
        {theme.patterns.header.flyout.homeIcon.svgPath ? (
          <SvgIcon
            xmlns="http://www.w3.org/2000/svg"
            viewBox={theme.patterns.header.flyout.homeIcon.viewBox}
            width={theme.patterns.header.flyout.homeIcon.width}
            height={theme.patterns.header.flyout.homeIcon.height}
          >
            <path
              d={theme.patterns.header.flyout.homeIcon.svgPath}
              fillRule="evenodd"
            />
          </SvgIcon>
        ) : (
          <Home />
        )}
      </HeaderLink>
      {responsiveFlyoutIcons &&
        responsiveFlyoutIcons.map(
          (icon) =>
            icon.value && (
              <HeaderLink
                aria-label={flyoutMenuIcons[icon.type].i18nText.ariaLabel}
                href={flyoutMenuIcons[icon.type].i18nText.url}
              >
                {flyoutMenuIcons[icon.type].svgPathElement}
              </HeaderLink>
            ),
        )}
    </React.Fragment>
  ) : null
}

const NavLabel = (props: {
  currentLevel: number
  showNavigationLabel?: boolean
  label?: string
}) =>
  props.showNavigationLabel && props.currentLevel !== 0 && props.label ? (
    <NavigationLabel>{props.label}</NavigationLabel>
  ) : (
    <Space />
  )

const getFlyoutFooter = (
  currentLevel: number,
  slotConfig: HeaderSlot[],
  headerText?: string,
  submenuProps?: SubmenuProps,
  sloti18nText?: { text: string; url: string }[],
  storeLocatorConfig?: boolean,
) =>
  currentLevel === 0 &&
  submenuProps && (
    <FlyoutFooter>
      <HeaderSlots
        headerType="flyout"
        i18nText={sloti18nText}
        slotConfig={slotConfig}
        link={
          <FlyoutHeaderLink
            storeLocatorConfig={storeLocatorConfig}
            headerText={headerText}
          />
        }
      />
      <Submenu {...submenuProps} />
    </FlyoutFooter>
  )

const getFlyoutAnimation = (
  flyoutPosition: FlyoutDirection,
  isInitialAnimation?: boolean,
) =>
  flyoutPosition === FlyoutDirection.RIGHT
    ? isInitialAnimation
      ? 'exit'
      : 'enter'
    : isInitialAnimation
    ? 'enter'
    : 'exit'

const navigationRenderer = (
  setTransitionDirection,
  selectedTabRef,
  navigationTree,
  selectedTabIndex,
) => {
  setTransitionDirection(1)
  selectedTabRef?.current?.scrollIntoView({
    inline: selectedTabIndex > navigationTree ? 'end' : 'center',
  })
}

const Icons = {
  closeIcon: (icon) =>
    icon.svgPath ? (
      <SvgIcon
        xmlns="http://www.w3.org/2000/svg"
        viewBox={icon.viewBox}
        width={icon.width}
        height={icon.height}
      >
        <path d={icon.svgPath} fillRule="evenodd" />
      </SvgIcon>
    ) : (
      <Close />
    ),
  chevronLeft: (icon) =>
    icon.svgPath ? (
      <SvgIcon
        xmlns="http://www.w3.org/2000/svg"
        viewBox={icon.viewBox}
        width={icon.width}
        height={icon.height}
      >
        <path d={icon.svgPath} fillRule="evenodd" />
      </SvgIcon>
    ) : (
      <ChevronLeft />
    ),
}

const getAnimationVariants = (shouldReduceMotion) => {
  return {
    enter: (direction: number) => {
      return shouldReduceMotion
        ? { opacity: 0 }
        : { x: direction > 0 ? '100%' : '-100%' }
    },
    center: shouldReduceMotion ? { opacity: 1 } : { x: 0 },
    exit: (direction: number) => {
      return shouldReduceMotion
        ? { opacity: 0 }
        : { x: direction < 0 ? '100%' : '-100%' }
    },
  }
}

const resetToTopLevel = (
  setCurrentLevel,
  currentLevelData,
  setPreviousLevelData,
  setCurrentLevelData,
  selectedTopLevelNavigationItem,
  setSelectedTabIndex,
  selectedTopLevelNavigationItemIndex,
  setLabel,
  displayName,
) => {
  setCurrentLevel(1)
  setPreviousLevelData(currentLevelData)
  setCurrentLevelData(selectedTopLevelNavigationItem)
  setSelectedTabIndex(selectedTopLevelNavigationItemIndex)
  setLabel(displayName)
}

const isImageCardList = (currentLevel, primaryNavImages) =>
  currentLevel === 0 && !!primaryNavImages?.length

const isImageHome = (currentLevel, levelHome) =>
  currentLevel !== 0 && levelHome && levelHome

const handleBackButtonClick = (
  setTransitionDirection,
  currentLevel,
  resetToInitialLevel,
  setShouldFocusAtLevelZero,
  setCurrentLevelData,
  previousLevelData,
  setLabel,
  displayName,
  setSelectedTabIndex,
  topLevel,
  previousLevelHome,
  setLevelHome,
  setCurrentLevel,
) => {
  setTransitionDirection(-1)
  const nextLevel = Math.max(currentLevel - 1, 0)
  if (nextLevel === 0) {
    resetToInitialLevel()
    setShouldFocusAtLevelZero(true)
  } else {
    setCurrentLevelData(previousLevelData)
    setLabel(displayName)
    setSelectedTabIndex(
      topLevel.findIndex((element) => element.displayName === displayName),
    )
  }
  if (nextLevel !== 0 && previousLevelHome) {
    setLevelHome(previousLevelHome)
  }
  setCurrentLevel(nextLevel)
}

const isThreeTierNav = (enableThreeTierNav, currentLevel) =>
  enableThreeTierNav && currentLevel === 1

export const FlyoutMenu = (props: FlyoutMenuProps) => {
  const flyoutRef = React.createRef<HTMLDivElement>()
  const selectedTabRef = React.createRef<HTMLButtonElement>()
  const { selectedTopLevelNavigationItemIndex = 0 } = props
  const { sectionHome = '' } = props.i18nText
  const navigation = props.navigationTree?.navigation
  const selectedTopLevelNavigationItem =
    navigation?.topLevel?.[selectedTopLevelNavigationItemIndex]

  const setScrollLock = useScrollLock()
  const shouldReduceMotion = useReducedMotion()
  const theme = useTheme()

  const [
    currentLevelData,
    setCurrentLevelData,
  ] = React.useState<NavigationItem | null>(
    props.hasSelectedTopLevelItem ? selectedTopLevelNavigationItem : null,
  )
  const [
    previousLevelData,
    setPreviousLevelData,
  ] = React.useState<NavigationItem | null>(currentLevelData)
  const [firstLevelData, setFirstLevelData] = React.useState<
    NavigationItem | undefined
  >(undefined)
  const [currentLevel, setCurrentLevel] = React.useState<number>(
    props.hasSelectedTopLevelItem ? 1 : 0,
  )

  const [levelHome, setLevelHome] = React.useState<React.ReactNode>(
    <NavigationTextItem
      data-testid="flyout-menu-home"
      text={vsprintf(sectionHome, [
        selectedTopLevelNavigationItem?.displayName,
      ])}
      hasChildren={false}
      link={selectedTopLevelNavigationItem?.link}
      enableThreeTierNav={props.enableThreeTierNav}
      showBottomBorder={props.enableThreeTierNav}
      shouldFocus
    />,
  )

  const [transitionDirection, setTransitionDirection] = React.useState<-1 | 1>(
    1,
  )
  const [label, setLabel] = React.useState<string | undefined>(
    selectedTopLevelNavigationItem?.displayName || '',
  )
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(
    selectedTopLevelNavigationItemIndex,
  )
  const [previousLevelHome, setPreviousLevelHome] = React.useState<
    React.ReactNode
  >()
  const [shouldFocusAtLevelZero, setShouldFocusAtLevelZero] = React.useState<
    boolean
  >(false)
  const [tabNumber, setTabNumber] = React.useState<number>(
    selectedTopLevelNavigationItemIndex,
  )

  const [enableAnimation, setEnableAnimation] = React.useState(true)

  React.useEffect(() => {
    // @TODO REBUILD-9147 Remove window & document? Or move to setScrollLock
    typeof document !== 'undefined' &&
      typeof window !== 'undefined' &&
      setScrollLock(props.isShowing, document, window)

    if (props.isShowing && props.hasSelectedTopLevelItem) {
      navigationRenderer(
        setTransitionDirection,
        selectedTabRef,
        props.navigationTree?.navigation.topLevel.length - 4,
        selectedTabIndex,
      )
    }
    return () => {
      if (props.isShowing) {
        setEnableAnimation(props.isShowing)
      }
    }
  }, [
    props.isShowing,
    selectedTabIndex,
    selectedTabRef,
    setScrollLock,
    props.navigationTree?.navigation.topLevel.length,
    props.hasSelectedTopLevelItem,
  ])

  React.useEffect(() => {
    selectedTabRef?.current?.scrollIntoView({
      inline:
        selectedTabIndex > props.navigationTree?.navigation.topLevel.length - 4
          ? 'end'
          : 'center',
    })
  }, [
    currentLevel,
    props.navigationTree?.navigation.topLevel.length,
    selectedTabIndex,
    selectedTabRef,
  ])

  const closeFlyout = () => {
    props.onClose()
    setShouldFocusAtLevelZero(false)
    if (props.hasSelectedTopLevelItem) {
      resetToTopLevel(
        setCurrentLevel,
        currentLevelData,
        setPreviousLevelData,
        setCurrentLevelData,
        selectedTopLevelNavigationItem,
        setSelectedTabIndex,
        selectedTopLevelNavigationItemIndex,
        setLabel,
        selectedTopLevelNavigationItem?.displayName,
      )
    } else {
      resetToInitialLevel()
    }
  }

  const resetToInitialLevel = () => {
    setCurrentLevel(0)
    setCurrentLevelData(null)
    setPreviousLevelData(null)
  }

  const disableExitAnimation = () => {
    setEnableAnimation(false)
    closeFlyout()
  }

  const shouldShowHomeSubheading = (
    currentLevel,
    showFirstLevel,
    showSecondLevel,
    homeText,
  ) =>
    ((showFirstLevel && currentLevel === 1) ||
      (showSecondLevel && currentLevel === 2)) &&
    homeText

  const keyDown = (e: React.KeyboardEvent) =>
    (e.key === 'Esc' || e.key === 'Escape') && closeFlyout()
  const variants = getAnimationVariants(shouldReduceMotion)
  const overlayVariants = {
    enter: { opacity: 0 },
    center: { opacity: 1 },
    exit: { opacity: 0 },
  }

  const getFlyoutNav = (theme: CombinedThemeInterface) => {
    const renderItem = (navigationItem: NavigationItem) =>
      renderNavigationItem(
        navigationItem,
        currentLevel,
        shouldFocusAtLevelZero,
        levelHome,
        currentLevelData,
        firstLevelData,
        setCurrentLevelData,
        setCurrentLevel,
        setPreviousLevelData,
        setPreviousLevelHome,
        setFirstLevelData,
        setLevelHome,
        setTransitionDirection,
        setSelectedTabIndex,
        props.i18nText,
        setLabel,
        theme,
        disableExitAnimation,
        selectedTabIndex,
        props.enableThreeTierNav,
        props.selectTopLevelNavigationItem,
        undefined,
        props.headerPromotionUrl,
        props.headerPromotionUrlTwo,
        props.headerPromotionUrls,
        props.navSubLevelPromotionList,
        props.navLevelThreePromotionList,
        props.hideFocusOnLevelOneNav,
        {
          selectedTopLevelNavigation:
            navigation?.topLevel[tabNumber]?.displayName ?? '',
          selectedSecondLevelNavigation: currentLevelData?.displayName,
          onClickCallback: props.navigationOnClickCallback,
        },
        setTabNumber,
      )

    const renderCurrentImage = (index: number) => {
      setLabel(currentLevelData?.displayName)
      setPreviousLevelHome(levelHome)
      setLevelHome(
        currentLevelData ? (
          <ImageThumbnailContent
            dynamicWidths={false}
            mobile={true}
            item={currentLevelData}
            i18nHomeText={props.i18nText.sectionHome}
            mobileImageWidth="56px"
            mobileImageHeight="56px"
            threeTierNav={props.enableThreeTierNav}
            headerTheme={theme.patterns.header}
            currentLevel={currentLevel}
          />
        ) : null,
      )
      setTransitionDirection(1)
      setPreviousLevelData(currentLevelData)
      setCurrentLevel(currentLevel + 1)
      setCurrentLevelData(currentLevelData?.subNavigation?.[index] || null)
      setSelectedTabIndex(index)
    }

    const defaultFlyoutOptions = currentLevelData?.subNavigation?.map(
      (navigationItem, index) => {
        return renderNavigationItem(
          navigationItem,
          currentLevel,
          shouldFocusAtLevelZero,
          levelHome,
          currentLevelData,
          firstLevelData,
          setCurrentLevelData,
          setCurrentLevel,
          setPreviousLevelData,
          setPreviousLevelHome,
          setFirstLevelData,
          setLevelHome,
          setTransitionDirection,
          setSelectedTabIndex,
          props.i18nText,
          setLabel,
          theme,
          disableExitAnimation,
          index,
          props.enableThreeTierNav,
          props.selectTopLevelNavigationItem,
          undefined,
          props.headerPromotionUrl,
          props.headerPromotionUrls,
          props.headerPromotionUrlTwo,
          props.navSubLevelPromotionList,
          props.navLevelThreePromotionList,
          props.hideFocusOnLevelOneNav,
          {
            selectedTopLevelNavigation:
              navigation?.topLevel[tabNumber]?.displayName ?? '',
            selectedSecondLevelNavigation: currentLevelData?.displayName,
            onClickCallback: props.navigationOnClickCallback,
          },
          setTabNumber,
        )
      },
    )

    const currentLevelType = (currentLevel, currentLevelData) => {
      const shouldShowLevelHome = shouldShowHomeSubheading(
        currentLevel,
        theme.patterns.header.navigation.showFirstLevelHome,
        theme.patterns.header.navigation.showSecondLevelHome,
        props.i18nText.sectionHome,
      )

      switch (currentLevelData.type) {
        case NavigationItemType.TEXT_CARD:
          return (
            <CardNavigationContent
              dynamicWidths={false}
              mobile={true}
              item={currentLevelData}
              i18nHomeText={props.i18nText.sectionHome}
            />
          )
        case NavigationItemType.IMAGE_CARD:
          return (
            <NavList>
              {shouldShowLevelHome && levelHome}
              <ImageCardNavigationContent
                dynamicWidths={false}
                mobile={true}
                item={currentLevelData}
                i18nHomeText={props.i18nText.sectionHome}
                isAmp={false}
                navItems={currentLevelData?.subNavigation?.map((navItem) =>
                  renderItem(navItem),
                )}
              />
            </NavList>
          )
        case NavigationItemType.THUMBNAIL_CARD:
          return (
            <ImageThumbnailContent
              dynamicWidths={false}
              mobile={true}
              item={currentLevelData}
              i18nHomeText={props.i18nText.sectionHome}
              mobileImageWidth={
                currentLevel === 1
                  ? theme.patterns.header.navigation.imageThumbnail.secondTier
                      .width
                  : theme.patterns.header.navigation.imageThumbnail.thirdTier
                      .width
              }
              mobileImageHeight={
                currentLevel === 1
                  ? theme.patterns.header.navigation.imageThumbnail.secondTier
                      .width
                  : theme.patterns.header.navigation.imageThumbnail.thirdTier
                      .width
              }
              threeTierNav={props.enableThreeTierNav}
              headerTheme={theme.patterns.header}
              currentLevel={currentLevel}
              onClick={(index: number) => {
                if (currentLevelData?.subNavigation) {
                  if (currentLevel === 0 || currentLevel === 1) {
                    renderCurrentImage(index)
                  }
                }
              }}
            />
          )
        default:
          return (
            <NavList>
              {shouldShowLevelHome && levelHome}
              {defaultFlyoutOptions}
            </NavList>
          )
      }
    }

    const getFlyoutContent = () => {
      if (currentLevelData) {
        return currentLevelType(currentLevel, currentLevelData)
      }
      return (
        <NavList
          isImageCardList={isImageCardList(
            currentLevel,
            props.primaryNavImages,
          )}
        >
          {isImageHome(currentLevel, levelHome)}
          {navigation?.topLevel?.map((navigationItem, index) => {
            return renderNavigationItem(
              {
                ...navigationItem,
                image: props.primaryNavImages?.[index]
                  ? {
                      url: props.primaryNavImages[index],
                      alt: '',
                    }
                  : undefined,
              },
              currentLevel,
              shouldFocusAtLevelZero,
              levelHome,
              currentLevelData,
              firstLevelData,
              setCurrentLevelData,
              setCurrentLevel,
              setPreviousLevelData,
              setPreviousLevelHome,
              setFirstLevelData,
              setLevelHome,
              setTransitionDirection,
              setSelectedTabIndex,
              props.i18nText,
              setLabel,
              theme,
              disableExitAnimation,
              index,
              props.enableThreeTierNav,
              props.selectTopLevelNavigationItem,
              false,
              props.headerPromotionUrl,
              props.headerPromotionUrlTwo,
              props.headerPromotionUrls,
              props.navSubLevelPromotionList,
              props.navLevelThreePromotionList,
              props.hideFocusOnLevelOneNav,
              {
                selectedTopLevelNavigation:
                  navigation?.topLevel[tabNumber]?.displayName ?? '',
                selectedSecondLevelNavigation: currentLevelData?.displayName,
                onClickCallback: props.navigationOnClickCallback,
              },
              setTabNumber,
            )
          })}
        </NavList>
      )
    }

    return (
      <FlyoutNav cardBackground={displayCardBackground(currentLevelData)}>
        {getFlyoutContent()}
      </FlyoutNav>
    )
  }

  return (
    <React.Fragment>
      {enableAnimation && (
        <CustomAnimatePresence custom={1}>
          {props.isShowing && (
            <FocusTrap
              focusTrapOptions={{
                clickOutsideDeactivates: props.clickOutsideDeactivates,
              }}
            >
              <div>
                <StyledFlyoutMenu
                  data-testid="flyout-menu"
                  key="flyout"
                  variants={variants}
                  initial={getFlyoutAnimation(props.flyoutPosition, true)}
                  animate="center"
                  exit={getFlyoutAnimation(props.flyoutPosition)}
                  custom={-1}
                  transition={{ ease: 'easeInOut', duration: 0.3 }}
                  onKeyDown={keyDown}
                  direction={props.flyoutPosition}
                  allowScrollYaxis={
                    !isThreeTierNav(props.enableThreeTierNav, currentLevel)
                  }
                >
                  <FlyoutHeaderWrapper>
                    <FlyoutHeader data-testid="flyout-header">
                      <FlyoutHeaderContent
                        data-test-id="flyout-header-content"
                        enableThreeTierNav={isThreeTierNav(
                          props.enableThreeTierNav,
                          currentLevel,
                        )}
                      >
                        {currentLevel !== 0 ? (
                          <HeaderButton
                            data-testid="flyout-header-content-back-button"
                            onClick={() =>
                              handleBackButtonClick(
                                setTransitionDirection,
                                currentLevel,
                                resetToInitialLevel,
                                setShouldFocusAtLevelZero,
                                setCurrentLevelData,
                                previousLevelData,
                                setLabel,
                                previousLevelData?.displayName,
                                setSelectedTabIndex,
                                props.navigationTree?.navigation?.topLevel,
                                previousLevelHome,
                                setLevelHome,
                                setCurrentLevel,
                              )
                            }
                          >
                            {Icons.chevronLeft(
                              theme.patterns.header.flyout.chevronLeftIcon,
                            )}
                            <HeaderButtonText>
                              {props.i18nText.back}
                            </HeaderButtonText>
                          </HeaderButton>
                        ) : (
                          <HeaderIcons
                            theme={theme}
                            i18nText={props.i18nText}
                            homeButtonOnClick={props.homeButtonOnClick}
                            hideHome={props.hideHome}
                            responsiveFlyoutIcons={props.responsiveFlyoutIcons}
                            sloti18nText={props.sloti18nText}
                          />
                        )}
                        <NavLabel
                          currentLevel={currentLevel}
                          showNavigationLabel={
                            theme.patterns.header.navigation.showNavigationLabel
                          }
                          label={label}
                        />
                        <HeaderButton
                          aria-label={props.i18nText.close}
                          onClick={() => closeFlyout()}
                        >
                          {Icons.closeIcon(
                            theme.patterns.header.flyout.closeIcon,
                          )}
                        </HeaderButton>
                        {currentLevel === 1 &&
                          props.enableThreeTierNav &&
                          !props.hideThreeTierTab && (
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                flexWrap: 'nowrap',
                                overflow: 'hidden',
                              }}
                            >
                              <TabList
                                role="tablist"
                                enableThreeTierNav={props.enableThreeTierNav}
                              >
                                {props.navigationTree?.navigation.topLevel.map(
                                  (tab, index: number) => (
                                    <Tab
                                      key={`tab-button-${index}`}
                                      id={`tab-button-${index}`}
                                      aria-controls={`tab-panel-${index}`}
                                      role="tab"
                                      ref={
                                        selectedTabIndex === index
                                          ? selectedTabRef
                                          : null
                                      }
                                      aria-selected={index === selectedTabIndex}
                                      onClick={() => {
                                        if (tab?.subNavigation) {
                                          props.selectTopLevelNavigationItem &&
                                            tab.link?.url &&
                                            props.selectTopLevelNavigationItem(
                                              tab.link.url,
                                            )
                                          setTabNumber(index)
                                          setLabel(tab?.displayName)
                                          setPreviousLevelHome(levelHome)
                                          setLevelHome(
                                            <NavigationTextItem
                                              text={vsprintf(sectionHome, [
                                                tab.displayName,
                                              ])}
                                              hasChildren={false}
                                              link={tab.link}
                                              enableThreeTierNav={
                                                props.enableThreeTierNav
                                              }
                                              showBottomBorder={
                                                props.enableThreeTierNav
                                              }
                                              isLevelHome
                                              shouldFocus
                                              navLevelThreePromotionList={
                                                props.navLevelThreePromotionList
                                              }
                                              topLevelName={
                                                firstLevelData?.displayName
                                              }
                                            />,
                                          )
                                          setTransitionDirection(1)
                                          setPreviousLevelData(currentLevelData)
                                          setCurrentLevel(currentLevel)
                                          setCurrentLevelData(tab)
                                          setSelectedTabIndex(index)
                                          props.navigationOnClickCallback &&
                                            props.navigationOnClickCallback({
                                              selectedTopLevelNavigation:
                                                navigation?.topLevel[index]
                                                  ?.displayName ?? '',
                                            })
                                        }
                                      }}
                                      borderStyle={'SingleTab'}
                                      enableThreeTierNav={
                                        props.enableThreeTierNav
                                      }
                                    >
                                      {tab.displayName}
                                    </Tab>
                                  ),
                                )}
                              </TabList>
                            </div>
                          )}
                      </FlyoutHeaderContent>
                    </FlyoutHeader>
                  </FlyoutHeaderWrapper>
                  <CustomAnimatePresence
                    initial={false}
                    custom={transitionDirection}
                  >
                    <StyledMotionDiv
                      ref={flyoutRef}
                      onAnimationComplete={() => {
                        const elementToFocus = flyoutRef?.current?.querySelector(
                          '[data-should-focus="true"]',
                        ) as HTMLElement
                        flyoutRef?.current && elementToFocus?.focus()
                        elementToFocus?.scrollIntoView({
                          block: 'center',
                        })
                      }}
                      key={currentLevel}
                      variants={variants}
                      initial="enter"
                      animate="center"
                      exit="exit"
                      custom={transitionDirection}
                      transition={{ ease: 'easeInOut', duration: 0.3 }}
                      maxHeight={currentLevel === 0}
                    >
                      {getFlyoutNav(theme)}
                      {getFlyoutFooter(
                        currentLevel,
                        props.slotConfig,
                        props.i18nText.headerText,
                        props.submenuProps,
                        props.sloti18nText,
                        props.storeLocatorConfig,
                      )}
                    </StyledMotionDiv>
                  </CustomAnimatePresence>
                </StyledFlyoutMenu>

                <FlyoutOverlay
                  key="overlay"
                  variants={overlayVariants}
                  initial="enter"
                  animate="center"
                  exit="exit"
                  transition={{ ease: 'easeInOut', duration: 0.3 }}
                  onClick={() => closeFlyout()}
                />
              </div>
            </FocusTrap>
          )}
        </CustomAnimatePresence>
      )}
    </React.Fragment>
  )
}
